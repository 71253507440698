






























































// TODO clean up unused dependencies
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {logDebug, logInfo} from '../utils/logger';
import Constants from '../utils/constants';
import {dbService} from '../services/FireStoreService';
import {createIframeHtml} from '../utils/SharePreview';
import { Getter, Action } from 'vuex-class';
import BottomFooter from '@/components/BottomFooter.vue';

@Component({
    components: {
        BottomFooter,
    },
    props: {
        docId: String,
    },
})
export default class DocShare extends Vue {

    @Getter('publishLocation') private publishLocation!: string;
    @Action('updateDocId') private updateDocId: any;
    @Action('updatePublishLocation') private updatePublishLocation: any;
    @Action('updateDocEdited') private updateDocEdited: any;
    private htmlDescription = Constants.DOCULET_DESCRIPTION;
    private keywords = Constants.DOCULET_SEO_KEYWORDS;
    private docId: string = this.docId;
    private iframeElement: HTMLIFrameElement | null = null;
    private iframeHeight = Constants.IFRAME_HEIGHT;

    private mounted() {
        this.loadDoc(this.docId, '');
        window.addEventListener('message', this.receiveMessage, false);
        this.iframeElement = document.getElementById(Constants.PREVIEW_IFRAME_ID) as HTMLIFrameElement;
    }

    get htmlTitle() {
        // TODO if I make docName value to set correctly for share, update title too
        return 'Doculet Share';
    }

    @Watch('docId')
    private loadDoc(docId: string, oldValue: string) {

        logDebug(`DocId to share ${docId}, oldValue: ${oldValue} , docId in store ${this.docId}`);
        this.updateDocId(docId);
        this.updateDocEdited(false);
     //   this.updatePublishLocation('http://localhost:8080/embed/twocode.html');
        if (!this.publishLocation) {
            dbService.getPublishLocation(docId).then( (location: string) => {
                this.updatePublishLocation(location);
            });
        }
    }

    get iframeHtml() {
        return createIframeHtml(this.publishLocation, this.iframeHeight);
    }
    private reloadIframe() {
        if (this.iframeElement) {
            this.iframeElement.src += '';
        }
    }

    private receiveMessage(event: any) {
        if (this.iframeElement) {
            const data = event.data;
            if (data && typeof data === 'string' && data.includes('iframe.resize')) {
                const jsonData = JSON.parse(data);
                if (jsonData.context === 'iframe.resize') {
                    this.iframeHeight = jsonData.height;
                }
            }
        }
    }
}
