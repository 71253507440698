



















import NavHeader from '@/components/NavHeader.vue';
import SideNav from '@/components/SideNav.vue';
import {Component, Vue} from 'vue-property-decorator';
import Slideout from 'vue-slideout';
import {isViewPage} from './utils/auth';

@Component({
    components: {
        NavHeader,
        Slideout,
        SideNav,
    },
})
export default class App extends Vue {
    private isMenuOpen: boolean = false;
    private onSideMenuOpen() {
        this.isMenuOpen = true;
    }
    private onSideMenuClose() {
        this.isMenuOpen = false;
    }

    get isViewPage() {
        return isViewPage(this.$route.path);
    }
}
