
















































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {gitHubService} from '../services/GitHubService';
import {s3Service} from '../services/AwsS3Service';
import {asciiDoc} from '../asciidoc';
import Constants from '../utils/constants';
import * as User from '../store/modules/user';

import {FireStoreService} from '../services/FireStoreService';
import {logDebug, logError} from '../utils/logger';
import * as auth from '../utils/auth';
import {applyHighlightJs, enrichHtml} from '../utils/HtmlEnricher';

import {
    Getter,
    Action,
} from 'vuex-class';
import {staticHostingUrl} from '../config/config';


@Component({
    props: {
        isMenuOpen: Boolean,
    },
})
export default class NavHeader extends Vue {

    @Getter('user') private user!: User.UserType;

    @Getter('docName') private docName!: string;
    @Getter('docOwnerId') private docOwnerId!: string;
    @Getter('docId') private docId!: string;
    @Getter('isDocSaved') private isDocSaved!: boolean;
    @Getter('isDocEdited') private isDocEdited!: boolean;
    @Getter('publishLocation') private publishLocation!: string;
    @Getter('content') private content!: string;
    @Action('updateDocSaved') private updateDocSaved: any;
    @Action('publishDoc') private publishDoc: any;
    @Action('addToMyDocs') private addToMyDocs: any;
    @Action('deleteFromMyDocs') private deleteFromMyDocs: any;
    @Action('signUserInGithub') private signUserInGithub: any;
    @Action('logout') private logout: any;

    private dbService!: FireStoreService;

    private importUrl: string = '';
    private menuIconName: string = 'bars';


    @Watch('isMenuOpen')
    private updateMenuIcon(isMenuOpen: boolean, oldValue: boolean) {
        if (isMenuOpen) {
            this.menuIconName = 'times';
        } else {
            this.menuIconName = 'bars';
        }
    }

    private importGist() {

        const gistId = gitHubService.parseUrl(this.importUrl);
        // TODO if doc is adoc, we should automatically save it to firestore.
        if (gistId != null) {
            this.openDocument(gistId);
        }
    }

    private mounted() {
        this.dbService = new FireStoreService();
    }

    get iconColor() {
        return Constants.MENU_ICON_COLOR;
    }

    private saveDoculet() {
        const token = localStorage.getItem(Constants.ACCESS_TOKEN_PROPERTY);
        if (this.user && this.docName && token) {
            if (!this.isDocSaved) {
                this.dbService.findDocIdByUserAndName(this.user.email, this.docName)
                    .then((querySnapshot) => {
                        if (querySnapshot.size > 0) {
                            this.updateExistingGistInDB(querySnapshot, token);
                        } else {
                            this.createGistAndAddToDB(token);
                        }
                    });
            } else {
                gitHubService.updateGist(token, this.docId, this.docName, this.content);
            }
        } else {
            logError('User or docName or token is null');
        }
    }

    private editDoculet() {
        if (this.docId) {
            this.openDocument(this.docId);
        }
    }

    private deleteDoculet() {
        const token = localStorage.getItem(Constants.ACCESS_TOKEN_PROPERTY);
        if (this.user && this.docName && token) {
            this.deleteGistAndFromDB(this.docId, token);
        } else {
            logError('User or docName or token is null');
        }
    }

    private openDocument(gistId: string) {
        this.$router.push(`/edit/${gistId}`);
    }

    private openNewDocument() {
        this.openDocument(Constants.NEW_DOC_GIST_ID);
    }

    private publishDoculet() {

        const docDir = s3Service.constructDocDir(this.docId);
        const bucketKey = s3Service.constructBucketKey(docDir);
        const docLocation = `${staticHostingUrl}${docDir}`;

        gitHubService.importGist(this.docId).then((gistFile) => {

            const html = asciiDoc.convert(gistFile.content);
            const enriched = enrichHtml(html, {docLocation, docId: this.docId, theme: 'idea.light.min.css'});
            s3Service.publishDoc(bucketKey, enriched);
            this.publishDoc({
                docId: this.docId,
                docName: this.docName,
                publishLocation: docLocation,
            });
            this.shareDoculet();
        });
    }

    private shareDoculet() {
        if (this.docId) {
            this.$router.push(`/share/${this.docId}`);
        }
    }

    private deleteGistAndFromDB(docId: string, token: string) {
        this.deleteFromMyDocs(docId);
        gitHubService.deleteGist(token, docId);
        this.dbService.getPublishLocation(this.docId).then( (location) => {
            this.dbService.deleteDoc(docId);
            if (location) {
                logDebug(`Deleting published doc: ${location}`);
                s3Service.deleteDoc(docId);
            }
            this.openDocument(Constants.GETTING_STARTED_DOC_GIST_ID);
        });
    }

    private updateExistingGistInDB(querySnapshot: firebase.firestore.QuerySnapshot, token: string) {

        const gistId = querySnapshot.docs[0].id; // We limit result to 1.
        const ownerId = querySnapshot.docs[0].data().ownerId;
        logDebug(`Found gist in FireStore : ${gistId} with ownerId : ${ownerId};
        Imported gist ownerId: ${this.docOwnerId}` );

        gitHubService.updateGist(token, gistId, this.docName, this.content).then( (newGist: any) => {
            this.updateDocSaved(true);
            this.openDocument(gistId);
        });

    }

    private createGistAndAddToDB(token: string) {

        // Create gist and save in firestore.
        gitHubService.saveGist(token, this.docName, this.content).then((newGist: any) => {
                const gistId = newGist.id;
                this.dbService.saveDoc(this.user!!.email, {
                    docId: gistId,
                    docName: this.docName,
                    docOwnerId: String(newGist.owner.id),
                    publishLocation: null,
                });
                this.addToMyDocs({docId: gistId, docName: this.docName});
                this.updateDocSaved(true);
                this.openDocument(gistId);
            });
    }

    get isUserAuthenticated() {
        return auth.isAuthenticated(this.user);
    }

    get isDocActionsDisabled() {
        return !this.isUserAuthenticated ||
            this.docName === null ||
            this.docId === Constants.ON_LOAD_DOC_CONTENT ||
            auth.isGuidePage(this.$route.path);
    }

    get isShareActionDisabled() {
        return this.isDocActionsDisabled || !this.publishLocation || auth.isSharePage(this.$route.path);
    }

    get isEditActionDisabled() {
        return this.isDocActionsDisabled || auth.isEditPage(this.$route.path);
    }

    get isSaveActionDisabled() {
        return this.isDocActionsDisabled || !this.isDocEdited;
    }

    get isPublishActionDisabled() {
        return this.isDocActionsDisabled || this.isDocEdited;
    }

    get isNewActionDisabled() {
        return !this.isUserAuthenticated || this.isDocEdited;
    }
}
