



























// TODO clean up unused dependencies
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {debounce} from 'typescript-debounce-decorator';
import {asciiDoc} from '../asciidoc';
import editor from 'vue2-ace-editor';
import {gitHubService} from '../services/GitHubService';
import {
    Getter,
    Action,
} from 'vuex-class';
import {FireStoreService} from '../services/FireStoreService';
import * as User from '../store/modules/user';
import {logWarn, logDebug} from '../utils/logger';
import Constants from '@/utils/constants';
import {DoculetDocBase} from '../store/modules/doculet';
import BottomFooter from '@/components/BottomFooter.vue';
import * as auth from '../utils/auth';

@Component({
    components: {
        editor,
        BottomFooter,
    },
    props: {
        gistId: String,
    },
})
export default class DocEditor extends Vue {

    private htmlDescription = Constants.DOCULET_DESCRIPTION;
    private keywords = Constants.DOCULET_SEO_KEYWORDS;

    @Getter('user') private user!: User.UserType;

    @Getter('docName') private docName!: string;
    @Getter('docOwnerId') private docOwnerId!: string;

    @Getter('content') private content!: string;

    @Action('updateDocName') private updateDocName: any;
    @Action('importDoc') private importDoc: any;
    @Action('updateDocContent') private updateDocContent: any;
    @Action('addToMyDocs') private addToMyDocs: any;

    private dbService!: FireStoreService;

    private gistId: string = this.gistId;

    private mounted() {
        // Don't load firebase for guide path to make spa-pre-render plugin to work.
        if (!auth.isGuidePage(this.$route.path)) {
            this.dbService = new FireStoreService();
        }
        if (this.gistId) {
            this.importGist(this.gistId, '');
        }
    }

    get compiledHtml() {
        const result = asciiDoc.convert(this.content);
        return result;
    }

    get htmlTitle() {
        return `Doculet Editor - ${this.docName}`;
    }

    get canonicalUrl() {
        const path = this.$route.path;
        const url = `${Constants.DOCULET_URL}${path.substring(1, path.length)}`;
        return url;
    }

    private editorInit() {
        require('brace/ext/language_tools'); // language extension prerequsite...
        require('brace/mode/asciidoc') ;   // language
        require('brace/theme/chrome');
    }

    @debounce(10, {leading: true})
    private update(e: string) {
        this.updateDocContent(e);
    }

    @Watch('gistId')
    private importGist(gistId: string, oldValue: string) {

        logDebug(`New gistId ${gistId} , Old value : ${oldValue}`);

        if (!gistId) {
            gistId = Constants.GETTING_STARTED_DOC_GIST_ID;
        }
        // TODO: content doesn't refresh if we import same gist again. because doesn't trigger.

        gitHubService.importGist(gistId).then((gistFile) => {
            // Content has to be updated before imported to correctly set docEdited flag
            this.update(gistFile.content);
            this.importDoc({
                docName: gistFile.fileName,
                docId: gistId,
                docOwnerId: gistFile.ownerId,
                });
        });
    }
}
