
































































































































import {Component, Vue} from 'vue-property-decorator';
import Constants from '../utils/constants';
import BottomFooter from '@/components/BottomFooter.vue';

@Component({
    components: {
        BottomFooter,
    },
})
export default class Home extends Vue {

    private title = Constants.DOCULET_TITLE;
    private description = Constants.DOCULET_DESCRIPTION;
    private keywords = Constants.DOCULET_SEO_KEYWORDS;

    private openGettingStarted() {
        this.$router.push(`/edit/${Constants.GETTING_STARTED_DOC_GIST_ID}`);
    }

    private scrollTo(refName: any) {
        const offsetTopProperty = 'offsetTop';
        const top = (this.$refs[refName] as any)[offsetTopProperty];
        window.scrollTo(0, top);
    }
}
