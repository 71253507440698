
































































































import {Component, Vue} from 'vue-property-decorator';
import Constants from '../utils/constants';
import BottomFooter from '@/components/BottomFooter.vue';

@Component({
    components: {
        BottomFooter,
    },
})
export default class About extends Vue {

    private title = 'About Doculet';
    private description = Constants.DOCULET_DESCRIPTION;
    private keywords = Constants.DOCULET_SEO_KEYWORDS;
}
