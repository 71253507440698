import Vue from 'vue';
import 'vue-awesome/icons/file-alt';
import 'vue-awesome/icons/folder-open';
import 'vue-awesome/icons/save';
import 'vue-awesome/icons/trash';
import 'vue-awesome/icons/eye';
import 'vue-awesome/icons/bars';
import 'vue-awesome/icons/link';
import 'vue-awesome/icons/code';
import 'vue-awesome/icons/copy';
import 'vue-awesome/icons/edit';
import 'vue-awesome/icons/pencil-alt';
import 'vue-awesome/icons/question';
import 'vue-awesome/icons/question-circle';
import 'vue-awesome/icons/clone';
import 'vue-awesome/icons/download';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/redo-alt';
import 'vue-awesome/icons/upload';
import 'vue-awesome/icons/share-square';
import 'vue-awesome/icons/share-alt';
import 'vue-awesome/icons/undo';
import 'vue-awesome/icons/cloud-upload-alt';
import 'vue-awesome/icons/window-close';
import 'vue-awesome/icons/brands/github-square';
import 'vue-awesome/icons/exclamation-triangle';

import Icon from 'vue-awesome/components/Icon.vue';

Vue.component('icon', Icon);
